import React, { useEffect, useState } from "react";
import "../styles/myAccount.scss";
import DashboardLayout from "../components/layouts/DashboardLayout";
import PageTitle from "../components/PageTitle";
import { logo } from "../assets/images";
import { FaEye, FaEyeSlash, FaRegCopy } from "react-icons/fa";
import { FcApproval, FcCancel } from "react-icons/fc";
import CustomInput from "../components/CustomInput";
import { useAuth } from "../context/userContext";
import { toast } from "react-toastify";
import moment from "moment";
import { MdPassword } from "react-icons/md";
import CustomModal from "../components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  clearErrors,
  clearUpdateState,
} from "../redux/actions/userAction";

const MyAccount = () => {
  const [showUpdatePassModel, setShowUpdatePassModel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { auth } = useAuth();
  const user = auth?.user;
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied!");
    } catch (err) {
      toast.warning("Failed to copy text");
    }
  };

  // CHNAGE PASSWORD HANDLER START

  const { loading, data, error } = useSelector((state) => state.changePassword);

  console.log("data", data);

  const handleChnagePassword = () => {
    if (!password || !confirmPassword || !oldPassword) {
      toast.warning("Please fill all the required fields");
    } else if (password <= 7) {
      toast.warning("Password must be minimum of 8 characters");
    } else if (password !== confirmPassword) {
      toast.warning("Password and confirm password must be same");
    } else {
      dispatch(changePassword(oldPassword, password));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (data?.success) {
      toast.success(data?.message);
      dispatch(clearUpdateState());
      setShowUpdatePassModel(false);
    }
  }, [error, data?.success, data?.message, dispatch]);

  // CHNAGE PASSWORD HANDLER ENDS

  return (
    <div className="myAccount_main">
      <DashboardLayout>
        <PageTitle title="My Account" />
        <div className="userContent">
          <div className="container">
            <div className="profilePic">
              <div className="imgSec">
                <img src={logo} alt="logo" />
                <div className="editBtn">
                  <button onClick={() => setShowUpdatePassModel(true)}>
                    <MdPassword />
                    Change Password
                  </button>
                </div>
              </div>
              <div className="impData">
                <div className="fullName">
                  {user?.fullName}{" "}
                  {user?.isActive ? <FcApproval /> : <FcCancel />}
                </div>
                <div className="uniqueID">
                  {user?.panNumber}{" "}
                  <FaRegCopy onClick={() => copyToClipboard(user?.panNumber)} />
                </div>
                <div className="emailID">{user?.designation?.title}</div>
                <div className="emailID">
                  Joining Date: {moment(user?.createdAt).format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
            <div className="userData">
              <CustomInput
                icon=""
                label="Full Name"
                type="text"
                // onChange=""
                placeholder="Enter Your Full Name"
                name="fullName"
                value={user?.fullName}
                // impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Email ID"
                type="email"
                // onChange=""
                placeholder="Enter Your Email ID"
                name="email"
                value={user?.email}
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Mobile Number"
                type="number"
                // onChange=""
                placeholder="Enter Your Mobile Number"
                name="phone"
                value={user?.phone}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Village"
                type="text"
                // onChange=""
                placeholder="Your Village"
                name="village"
                value={user?.village}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="City"
                type="text"
                // onChange=""
                placeholder="City"
                name="city"
                value={user?.city}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="State"
                type="text"
                // onChange=""
                placeholder="State"
                name="state"
                value={user?.state}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Pin Code"
                type="number"
                // onChange=""
                placeholder="Pin Code"
                name="pinCode"
                value={user?.pinCode}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Status"
                type="text"
                // onChange=""
                placeholder="Profile Status"
                name="profileStatus"
                value={user?.isActive ? "Approved" : "Disabled"}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
            </div>
          </div>
        </div>
      </DashboardLayout>

      {/* CHNAGE PASSWORD MODEL START */}
      <CustomModal show={showUpdatePassModel} setShow={setShowUpdatePassModel}>
        <div className="updatePasswordModal">
          <h2
            style={{
              fontSize: "20px",
              textAlign: "center",
              color: "#fff",
              marginBottom: 20,
              textTransform: "uppercase",
            }}
          >
            Update Password
          </h2>
          <CustomInput
            label="Old Password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter Old Password"
            name="oldPassword"
            onChange={(e) => setOldPassword(e.target.value)}
            borderColor="#42275a"
            eyeIcon={!showPassword ? <FaEye /> : <FaEyeSlash />}
            setshowPassword={setShowPassword}
          />
          <CustomInput
            label="New Password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter New Password"
            name="newPassword"
            onChange={(e) => setPassword(e.target.value)}
            borderColor="#42275a"
            eyeIcon={!showPassword ? <FaEye /> : <FaEyeSlash />}
            setshowPassword={setShowPassword}
          />
          <CustomInput
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            placeholder="Confirm New Password"
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            borderColor="#42275a"
            eyeIcon={!showPassword ? <FaEye /> : <FaEyeSlash />}
            setshowPassword={setShowPassword}
          />
          <button
            className="updatePasswordBtn"
            onClick={handleChnagePassword}
            style={{
              color: "white",
              background: "grey",
              padding: "9px 15px",
              margin: "0 auto",
              display: "block",
              border: "none",
              outline: "none",
              cursor: "pointer",
              borderRadius: "5px",
            }}
            disabled={loading}
          >
            {loading ? "Loading..." : "Update Password"}
          </button>
        </div>
      </CustomModal>
      {/* CHNAGE PASSWORD MODEL ENDS */}
    </div>
  );
};

export default MyAccount;
