import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import styled, { css } from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const ModalContent = styled.div`
  background: #42275a;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  box-sizing: border-box;
  @media screen and (max-width: 500px) {
    max-width: 100%;
    width: 100%;
    max-height: 100vh;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #f2f2f2;
  transition: color 0.2s ease;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  border-bottom-left-radius: 50%;
  transition: 0.4s;

  &:hover {
    background: red;
  }
`;

const CustomModal = ({ show, setShow, children }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [show]);
  return (
    <Modal show={show}>
      <ModalContent className="custonScroll">
        <CloseButton title="Close" onClick={() => setShow(false)}>
          <AiOutlineClose />
        </CloseButton>
        {children}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
