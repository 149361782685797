import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import {
  changePasswordReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  userLoginReducer,
  userProfileReducer,
} from "./reducers/userReducer.js";
import { getAgentPoliciesReducer, getDirectTeamPoliciesReducer } from "./reducers/policyReducer.js";
import { getMyTeamReducer } from "./reducers/teamReducer.js";
import { getAllCompaniesReducer } from "./reducers/companyReducer.js";

// get user from sessionStorage
const userFromSessionStorage = sessionStorage.getItem("jsrClient")
  ? JSON.parse(sessionStorage.getItem("jsrClient"))
  : {};

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  userLogin: userLoginReducer,
  profile: userProfileReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  changePassword: changePasswordReducer,

  // POLICY REDUCER START
  agentPolicies: getAgentPoliciesReducer,
  directTeamPolicy: getDirectTeamPoliciesReducer,
  // POLICY REDUCER ENDS

  // TEAM REDUCER START
  myTeam: getMyTeamReducer,
  // TEAM REDUCER ENDS

  // INSURER COMPANIES START
  allInsurerCompanies: getAllCompaniesReducer,
  // INSURER COMPANIES ENDS
});

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromSessionStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
